<template>
  <div :class="'dataset-tile' + (isInvisible ? ' invisible' : '')" @click="onClick">
    <div class="strap" :style="strapBackground"></div>
    <div class="detail">
      <h3>{{ title || "Unbenannt" }}</h3>
      <span class="description">{{ description }}</span>
    </div>
    <div class="actions">
      <div class="dataset-icon-button" @click.stop="onDeleteDataset()">
        <inline-svg class="icon icon-trash" :src="require('@/assets/svg/icons/reports/icon-trash.svg')" width="18" height="18"></inline-svg>
      </div>
      <div class="dataset-icon-button" @click.stop="onToggleDatasetVisibility()">
        <inline-svg class="icon icon-visbility" :src="require('@/assets/svg/icons/reports/icon-visible.svg')" width="18" height="18"></inline-svg>
      </div>
      <div v-if="showContextMenu" class="dataset-icon-button context-menu-button"
        @click.stop="$store.dispatch('ui/toggleContextMenu',
          { event: $event, data: reportViewData, menuItems: contextMenuItems })">
        <inline-svg
          :src="require('@/assets/svg/icons/reports/icon-dots.svg')" width="18" height="18">
        </inline-svg>
      </div>
    </div>
  </div>
</template>

<script>

import { palettes } from '@/store/reports.module'
import ReportPlotMixin from "@/components/mixins/ReportPlotMixin.vue"
import Util from "@/util.js"

export default {
  name: 'DatasetTile',
  mixins: [ReportPlotMixin],
  props: {
    view: {
      type: Object,
    },
    dataset: {
      type: Object,
    },
    datasetIndex: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    strapBackground() {
      const n_colors = this.dataset?.chartData?.y?.length || 1
      let color = this.dataset?.style?.color || "hotpink"
      let bg = color
      if (color.startsWith("palette:")) {
        let colors = palettes[color.replace("palette:", "")]
        if (this.dataset?.grouping) {
          let gradient_colors = []
          for (let i = 0; i < n_colors; ++i) {
            gradient_colors.push(colors[i % colors.length])
          }
          bg = Util.colorsToLinearGradient(gradient_colors)
        } else {
          bg = colors[0]
        }
      } else if (this.dataset?.grouping) {
        let colors = Util.createColorMap(n_colors + 1, color, 0.65)
        colors.pop()
        bg = Util.colorsToLinearGradient(colors)
      }
      return {
        background: bg
      }
    },
    title() {
      return this.dataset?.title
    },
    label() {
      return '' + (this.datasetIndex + 1)
    },
    description() {
      return this.dataset?.description
    },
    sourceType() {
      return this.dataset?.sourceType
    },
    contextMenuItems() {
      let items = []

      items.push({
        title: "Duplicate",
        handler: () => { this.onDuplicate() },
      })

      if (this.stackingAllowed(this.sourceType)) {
        const isStacked = this.stackingSelected(this.view, this.sourceType)
        const action = isStacked ? "Unstack" : "Stack"
        const source_type_label = this.getSourceTypeConfig(this.sourceType)?.labelPlural || this.sourceType
        items.push({
          title: `${action} all ${source_type_label.toLowerCase()}`,
          handler: () => {
              this.onToggleStack()
            },
        })
      }
      if (this.logAxisAllowed(this.sourceType)) {
        const isLog = this.logAxisSelected(this.view, this.sourceType)
        const newScale = isLog ? "linear" : "log"
        items.push({
          title: `Show on ${newScale} axis`,
          handler: () => {
              this.onToggleLogAxis()
            },
        })
      }
      if (this.relativeAxisAllowed(this.sourceType)) {
        const isRelative = this.relativeAxisSelected(this.view, this.sourceType)
        const newOption = isRelative ? "absolute" : "relative"
        const source_type_label = this.getSourceTypeConfig(this.sourceType)?.labelPlural || this.sourceType
        items.push({
          title: `Show all ${source_type_label.toLowerCase()} on ${newOption} axis`,
          handler: () => {
              this.onToggleRelativeAxis()
            },
        })
      }
      return items
    },
    showContextMenu() {
      return this.contextMenuItems.length > 0
    },
    isInvisible() {
      return this.isDatasetFullyHidden(this.dataset)
    },
  },
  methods: {
    onClick() {

    },
    onDeleteDataset() {
      this.$emit("dataset:delete")
    },
    onToggleDatasetVisibility() {
      this.$emit("visibility:toggle")
    },
    onToggleLogAxis() {
      this.$emit("yaxis:toggleLogAxis", this.dataset)
    },
    onToggleStack() {
      this.$emit("yaxis:toggleStack", this.dataset)
    },
    onToggleRelativeAxis() {
      this.$emit("yaxis:toggleRelativeAxis", this.dataset)
    },
    onDuplicate() {
      this.$emit("dataset:duplicate")
    }
  }
}
</script>

<style scoped>
.dataset-tile {
  /* background: var(--c-white-grey); */
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 80px;
  border-radius: 7px;
  cursor: pointer;
  margin: 0 10px 10px 0;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1px;
  padding: 0 16px;
  border: 1px solid #E6EBF7;
  color: var(--c-dark-blue);
  transition: opacity 0.25s ease-in-out;
  background: #F7F8FC;
  position: relative;
  overflow: hidden;
}
.detail {
  padding-left: 20px;
  flex-grow: 1;
}

h3 {
  margin: 0;
  text-transform: none;
  font-size: 18px;
  letter-spacing: normal;
}

.description {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--c-deep-grey);
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.actions {
  color: var(--c-deep-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon:hover {
  color: #000;
}

.invisible {
  opacity: 0.25;
  transition: opacity 0.25s ease-in-out;
}
.dataset-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 36px;
}

.strap {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  height: 100%;
}
</style>
